import React from 'react';
import { Helmet } from 'react-helmet';
import logo from '../images/logo-omega-station.svg';
import './index.scss';

export default () => (
  <>
    <Helmet>
      <meta name="author" content="Omega Station • Paul Kevin Koehler" />
      <meta name="designer" content="Jon McCallum Design" />
      <meta
        name="description"
        content="Omega Station is a web development company based in Vancouver, British Columbia, providing custom web solutions for domestic and international clientele."
      />
      <title>Omega Station • Web Development • Vancouver, British Columbia</title>
    </Helmet>
    <div className="content">
      <img src={logo} alt="Omega Station • Paul Kevin Koehler" />
      <p>
        <span>Paul Kevin Koehler</span>
        <span className="separator">•</span>
        <span>604.790.6176</span>
        <span className="separator">•</span>
        <span>
          <a href="mailto:paul@omegastation.ca">paul@omegastation.ca</a>
        </span>
      </p>
    </div>
  </>
);
